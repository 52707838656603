/* global $ */

const POLL_API_OPTIONS = '/api/v1/poll/[slug]'
const POLL_API_VOTE = '/api/v1/poll/[slug]/[key]'
const OPTIONS_SELECTOR = '.poll--options'
const OPTION_SELECTOR = '.poll--option'
const BUTTON_SELECTOR = '.poll--option__button'
const NUMBER_SELECTOR = '.poll--option__number'
const option_template = '<div class="poll--option grid__cell 1/2 1/4--desk"><button class="btn btn--outline poll--option__button"></button><div class="poll--option__number"></div></div>'


function initPoll(container){
  const key = container.attr('data-key');
  const slug = container.attr('data-slug');
  const state = {
    options: [],
    results: {},
    showResults: false,
    choices: {},
    options_url: (''+POLL_API_OPTIONS).replace('[slug]', slug),
    vote_url: (''+POLL_API_VOTE).replace('[slug]', slug).replace('[key]', key),
  };

  // Get options for poll and render poll
  $.getJSON(state.options_url).done(function(data){
    state.options = data.options;
    renderPoll(container, state);
    $.getJSON(state.vote_url).done(function(data){
      state.choices = data.choices;
      state.results = data.results;
      // Render results only if user has voted before
      state.showResults = (Object.keys(data.choices).length > 0);
      renderPollResults(container, state);
    });
  });
}
window.initPoll = initPoll;

function renderPoll(container, state){
  const optionsContainer = container.find(OPTIONS_SELECTOR)
  // Render each option and bind click handler
  state.options.forEach(function(option){
    const el = $(option_template)
    const id = option.option_id
    el.attr('data-id', id)

    const button = el.find(BUTTON_SELECTOR)
    button.text(option.name)
    button.on('click', function(){
      // Calculate local result
      if (state.choices[id]){
        state.choices[id] = undefined
        state.results[id] = Math.max((state.results[id] || 0) - 1, 0)
      } else {
        state.choices[id] = true
        state.results[id] = (state.results[id] || 0) + 1
      }
      state.showResults = true

      // Render results
      renderPollResults(container, state);

      // Send vote
      $.post(state.vote_url, {option_id: id}).done(function(data){
        state.choices = data.choices;
        state.results = data.results;
        renderPollResults(container, state);
      });
    });

    el.find(NUMBER_SELECTOR).hide();

    optionsContainer.append(el)
  });

  if (state.options.length > 0) {
    container.show()
  }
}

function renderPollResults(container, state){
  // Re-render each option
  container.find(OPTION_SELECTOR).each(function(){
    const el = $(this);
    const id = parseInt(el.attr('data-id'), 10);
    let count = null;
    if (state.results && state.showResults) {
      count = state.results[id] || 0;
    }

    // Highlight current choice
    const button = el.find(BUTTON_SELECTOR);
    const chosen = state.choices[id] === true;
    button.toggleClass('poll--option__button--active', chosen).toggleClass('btn--outline', !chosen);

    // Show numbers after voted
    const numberEl = el.find(NUMBER_SELECTOR);
    if (count !== null){
      numberEl.text(count);
      numberEl.show();
    } else {
      numberEl.hide();
    }
  });
}

(function(){
  if (typeof($) === 'function'){
    $('.js-poll').each(function(){
      initPoll($(this));
    });
  }
}());
